  #section4 {
    padding-top: 10%;    
    color: rgb(56, 56, 56);
  }  
  
  .section4 h1 {
    font-weight: bold;
    text-align: left;
    font-size: 35px;
  }
  
  .section4 h2 {
    font-weight: bold;
    text-align: left;
    font-size: 24px;
  }
  
  .section4 p {
    font-size: 18px;
    text-align:left;
    line-height: 2; /* Ajustez la valeur selon vos préférences */
  }

  .section4 a {
    font-size: 50px;
  }

  .S4_action {
    background-color: rgb(192, 199, 199, 0.4);
    border-radius: 4px;
    padding-top:2%;
  }

  .S4 {
    border-radius: 4px;
    padding-top:2%;
  }
  
  .S4_email {
    border-bottom: 1px solid rgb(56, 56, 56);
    font-weight:bold;
    outline: none;
    color: rgb(0, 67, 67);
  }
  .S4_email::placeholder {
    color: rgb(0, 67, 67); /* Remplacez par la couleur souhaitée */
    font-weight:bold;
  }
  
  .S4_button_signUp {
    background-color: rgb(0, 67, 67);
    font-size: 18px;
    border: none; /* Remove border */
    cursor: pointer; /* Add a pointer cursor on hover (optional) */
    color: white;
    padding: 15px 20px; /* Adjust the values as needed */
    border-radius: 4px;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)
  }
  
  .S4_button_signUp:hover{
    transform: scale(1.1);
    color: black;
  }
  
  
  .email-message {
    color: rgb(0, 67, 67); /* Changer la couleur du texte en bleu */
    font-weight:bold;
  
  }
  .email-error {
    color: rgb(209, 27, 27); /* Définir la couleur du texte en rouge */
    font-size: 18px;
  
  }
  

  
  
  
/* Utilisation des mêmes breakpoints que ceux d'Ant Design */
@media screen and (max-width: 990px) {

    #section4 {
        padding-top:18%;
      }  


    .section4 h1 {
        font-size: 5vw;
      }

    .section4 h2 {
        font-size: 4vw;
      }

    .section4 p {
        font-size: 3.5vw;
    }

    .section4 a {
        font-size: 8vw;
      }

  }


  
  
  