@font-face {
  font-family: eurostile;
  src: url('../../public/eurostile.woff') format('woff2'),
       url('../../public/EuroStyle\ Normal.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
    font-family: eurostile, sans-serif;
    background: #ffffff; /* Ajoutez vos couleurs préférées ici */
    color: #004343;
    height:100vh;
    margin:0;
}

* {
  box-sizing: border-box;
}

body{
  margin: 0;
  background-image: url('../optimized_media/background.avif'); 
  background-position: center;
  background-repeat: repeat-y;
  width: 100%;
  height:auto;
  background-size: 100% auto; 
}


section {
  padding-top: 5%;
  min-height: 100vh;
}

h1, h2, h3,  .ant-tabs-tab-btn, .CTA, button, input {
  font-family: Lato, sans-serif;
}

p {
  font-family: eurostile, sans-serif;
}


h1 {
  
}

